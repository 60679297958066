<template>
  <div v-if="fields.length > 0">
    <form v-on:submit.prevent="(e)=>e.preventDefault()" ref="form">
      <GridLayout :responsive="!!this.$isMobile()" ref="grid" :layout.sync="fields" :col-num="12" :row-height="this.rowHeight" :is-draggable="false" :is-resizable="false" :margin="[this.horizontalGap,this.verticalGap]" :use-css-transforms="false">
        <grid-item class="x-grid-item" v-for="item in fields" :x="item.x" :y="item.y" :w="item.w" :h="item.h" :i="item.i" :key="item.cid">
          <Field :item="item" :rowHeight="rowHeight" />
        </grid-item>
      </GridLayout>
      <div ref="hiddenContainer" class="hidden-fields">
        <input type="hidden"  v-for="field in hiddenFields" :name="'__F'+field.fieldId" :value="field.value" :key="'__F'+field.fieldId"/>
      </div>

      <div class="file-pond-box" v-if="this.setting.allowAttachment">
        <file-pond
          v-if="this.setting.allowAttachment"
          ref="pond"
          name="file"
          allow-multiple="true"
          :max-files="10"
          max-file-size="5MB"
          @removefile="handleRemoveFile"
          :server="{
            process: {
              url: this.$store.state.webform.api + 'action.do',
              method: 'POST',
              withCredentials: true,
              headers: {
                webformToken: this.$store.state.webform.formId
              },
              onload: (response) => {
                return this.$refs.pond.getFiles()[0].id
                // console.log(this.$refs.pond.getFiles()[0].filename)
                // console.log(this.$refs.pond.getFiles()[0].id)
              },
              timeout: 9000,
              ondata: (formData) => {
                formData.append('webformObjectId',this.$store.state.webform.objectId);
                formData.append('webformToken',this.$store.state.webform.formId);
                formData.append('instanceId',this.$store.state.webform.instanceId);
                formData.append('action','action.tenant.webform.v1.tokenbase.webformtokenbase.UploadWebformAttachmentAction')
                return formData;
              }
            },
            revert: null
          }"
          @init="handleInit"
        />
      </div>
      

      <div v-if="this.setting.other.showIp" class="wintouch-gdpr-container">
        <div class="wintouch-gdpr-label wintouch-field-checkbox wintouch-gdpr-field">
          {{this.setting.other.showIpText}} {{this.setting.other.clientIp || 'Unknown'}}
        </div>
      </div>

      <div v-if="this.setting.other.enableGDPR" class="wintouch-gdpr-container">
        <div class="wintouch-gdpr-field">
          <label class="wintouch-gdpr-label wintouch-field-checkbox" for="gdpr" style="border:none !important;height:fit-content !important;" >
            <input style="margin:5px;" ref="gdpr" id="gdpr" type="checkbox" true-value="true" false-value="false" >{{setting.other.gdprText}} <span style="color:red;">*</span>
          </label>
          <div v-if="this.showGDPRError" ref="tip" class="wintouch-field-empty-tip" style="margin-left: 23px;">
            Please complete this required field.
          </div>
        </div>


      </div>

      <div class="wintouch-submit-container">
        <vue-recaptcha v-if="this.setting.other.enableRecaptcha" ref="recaptcha" @verify="onVerify" @expired="onExpired" :sitekey="this.setting.other.recaptchaKey" :loadRecaptchaScript="true">
          <button ref="submitBtn" v-bind:class="{ disabled: isSubmitting}" class="wintouch-submit-button">{{setting.other.btnText}}</button>
        </vue-recaptcha>
        <button v-bind:class="{ disabled: isSubmitting}" v-if="!this.setting.other.enableRecaptcha" @click="beforeOnSubmit" ref="submitBtn" class="wintouch-submit-button">{{setting.other.btnText}}</button>
        <!-- <button @click="testClick" href="#">Download now!</button> -->
      </div>

    </form>
  </div>
</template>
<script>
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond/dist/filepond.min.css';
import vueFilePond from 'vue-filepond';
import VueGridLayout from 'vue-grid-layout';
import VueRecaptcha from 'vue-recaptcha';
import { createHelpers } from "vuex-map-fields";
import Field from "../fields/Field";

const { mapFields } = createHelpers({
  getterType: 'webform/getField',
  mutationType: 'webform/updateField',
});
const FilePond = vueFilePond(FilePondPluginFileValidateSize);
export default {
  name: "Grid",
  computed: {
    ...mapFields(["fields", "hiddenFields", "styles", "rowHeight", "setting", "status", "verticalGap", "horizontalGap"])
  },
  props: ["formId"],
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    Field: Field,
    FilePond,
    VueRecaptcha
  },
  data(){
    return {
      showGDPRError: false,
      isSubmitting: false
    }
  },
  // mounted: function () {
  //   this.$nextTick(function () {
  //     this.$refs.pond.on('init', () => {
  //       const element = document.querySelector('.filepond--credits');
  //       if (element) {
  //         element.style.display = 'none'; // 隐藏元素
  //         // 或者使用 element.remove(); 来删除元素
  //       }
  //     });
  //   })
  // },  
  methods: {
    onVerify(response){
      // window.console.log(response);
      this.beforeOnSubmit(null);
    },
    onExpired(){
      alert("reCaptcha is expired, Please refresh the page and try again.");
    },
    gtagClick(){
      this.$gtag.event('sign_up',{
        event_callback: function (e) {
          window.console.log("sign_up to gtag")
        }
      })
    },
    validateFields(){
      return new Promise((resolve, reject)=>{
        let needValidateCount = 0;
        let fields = [];
        // filter the truly components in grid layout.
        this.$refs.grid.$children.forEach((i) => {
          if (i.$children.length > 0) {
            if (i.$children[0].$children.length > 0) {
              fields.push(i.$children[0])
            }
          }
        });
        fields.forEach((f)=>{
          f.validate().then(()=>{
            needValidateCount += 1;
            if(needValidateCount === fields.length){
              // window.console.log("go to submit");
              resolve();
            }
          }).catch(()=>{
            // window.console.log("do nothing!");
            reject();
          });
        });

      });

    },
    beforeOnSubmit(event){

      if(this.isSubmitting){
        // window.console.log("is submitting!");
        return;
      }else{
        this.isSubmitting = true;
      }
      this.validateFields().then(()=>{
        this.submit();
      }).catch(()=>{
        this.isSubmitting = false;
        // if(this.$refs.recaptcha != undefined){
        //   this.$refs.recaptcha.reset();
        // }
      });
    },
    submit(event) {
      if(this.setting.other.enableGtag){
        window.console.log("to analytics service")
        this.gtagClick();
      }
      if(this.setting.other.enableGDPR){
        if(!this.$refs.gdpr.checked){
          this.showGDPRError = true;
          this.isSubmitting = false;
          // if(this.$refs.recaptcha != undefined){
          //   this.$refs.recaptcha.reset();
          // }
          return;
        }else{
          this.showGDPRError = false;
        }
      }
      let formData = {};
      this.$refs.hiddenContainer.children.forEach((h) => {
        formData[h.name] =  h.value;
      });
      this.$refs.grid.$children.forEach((i) => {
        if (i.$children.length > 0) {
          if (i.$children[0].$children.length > 0) {
            let val = i.$children[0].$children[0].$data.value;
            if (i.$children[0].item !== undefined) {
              if (i.$children[0].item.name !== undefined) {
                if (val instanceof Array) {
                  // is spinner
                  if (val.length === 0) {
                    formData[i.$children[0].item.name]="";
                  } else {
                    let values = [];
                    val.forEach((a) => {
                      values.push(a.value);
                    });
                    formData[i.$children[0].item.name] = values.join();
                  }
                } else if (val instanceof Object) {
                  formData[i.$children[0].item.name] = val.value;
                } else {
                  formData[i.$children[0].item.name] = val;
                }
              }
            }

          }
        }
      });

      // const pondFiles = this.$refs.pond.getFiles();
      // for( let i = 0; i< pondFiles.length; i++){
      //   formData.append("file", pondFiles[i].file)
      // }

      formData["webformToken"] = this.formId;
      formData["instanceId"] = this.$store.state.webform.instanceId;
      if(this.$extra !== undefined){
        for(let key in this.$extra){
          formData[key] = this.$extra[key];
        }
      }

      this.$store.dispatch("webform/submit", formData).then((result) => {
        if (!result.success) {
          this.$emit('setDetailMsg', result.message);
          // if(this.$refs.recaptcha != undefined){
          //   this.$refs.recaptcha.reset();
          // }
        }
        this.$modal.show("modal-"+this.formId);
        this.isSubmitting = false
      }).catch((error)=>{
        this.$emit('setDetailMsg', error)
        this.$modal.show("modal-"+this.formId);
        // if(this.$refs.recaptcha != undefined){
        //   this.$refs.recaptcha.reset();
        // }
        this.isSubmitting = false
      });
    },
    getClientIp(){

    },
    handleInit(){
      this.$nextTick(() => {
        const element = document.querySelector('.filepond--credits');
        if (element) {
          element.style.display = 'none'; // 隐藏元素
          // 或者使用 element.remove(); 来删除元素
        }
      });
    },
    handleRemoveFile(event,file){
      const formData = new URLSearchParams();
      formData.append('fileName', file.filename);
      formData.append('instanceId', this.$store.state.webform.instanceId);
      formData.append('webformObjectId', this.$store.state.webform.objectId);
      formData.append('webformToken', this.$store.state.webform.formId);
      formData.append('action', 'action.tenant.webform.v1.tokenbase.webformtokenbase.DeleteWebformAttachmentAction');

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: formData
      }
      fetch(this.$store.state.webform.api + 'action.do',requestOptions)
        .then(response => {
          if (response.ok) {
            //console.log('附件删除成功');
            // this.$refs.pond.removeFile(file.id);
          } else {
           // console.error('附件删除失败');
          }
        })
        .catch(error => {
          //console.error('网络错误:', error);
        });
    }
  }
}
</script>
<style scoped>
  .wintouch-gdpr-container{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .wintouch-gdpr-field{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
  }
  .disabled.wintouch-submit-button{
    opacity: 0.65;
  }
  .file-pond-box{
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中，如果需要的话 */
    width: 100%; /* 占满父容器宽度 */
    padding: 20px; /* 根据需要调整 */
    box-sizing: border-box;
  }
  .file-pond-box > .filepond--wrapper{
    max-width: 50%;
    min-width: 360px;
    width: 100%;
  }
</style>
