import { getField, updateField } from "vuex-map-fields";
import setting from '../api/setting';

export class WebFormModule{
  constructor(){
    console.log('A constructor()')
  }


  static get instance() {

    const state = {
      passValidate: false,
      api: "https://wintouch.net",
      styles: "",
      formId:"",
      fields: [],
      hiddenFields: [],
      instanceId: "",
      objectId: "",
      setting: {
        allowAttachment: false,
        msg: {
          fail: "",
          success: ""
        },
        other: {
          btnText: "",
          redirectUrl: "",
          successRedirectUrl:"",
          failRedirectUrl:"",
          recaptchaKey:"",
          enableRecaptcha:false,
          enableGDPR:false,
          enableGtag: false,
          gtagResourceId: "",
          gdprText:"",
          showIp: false,
          showIpText: "",
          clientIp: '',
          // gtagSettings:{
          //   accountId: "",
          //   event: {
          //     action: "submit"
          //   }
          // }
        }
      },
      rowHeight: 80,
      verticalGap: 0,
      horizontalGap: 10,
      status: "",
      debugMode: false,
    };

    const getters = {

    };

    const actions = {

      validateField({state},payload){
        return new Promise((resolve,reject) => {
          setting.validate({ formData: payload, api: state.api }).then((result) => {
            resolve(result.data)
          }).catch((error)=>{
            console.log("akdlfasdf");
          });
        })
      },

      get({ commit, state }, payload) {
        commit('setFormID',payload.formId);
        commit('setDebugMode',payload.debugMode);
        return new Promise((resolve) => {
          commit('setLayoutSetting', {});
          setting.get({ webformToken: payload.formId, api: state.api }).then((result) => {
            commit('setLayoutSetting', result.data);
            resolve(result.data)
          })
        })
      },
      submit({ commit, state }, formData) {
        if(state.debugMode){
          formData.append("debugMode",true);
        }
        return new Promise((resolve,reject) => {
          setting.submit({ formData: formData, api: state.api }).then((result) => {
            if (result.success) {
              commit('success', result.data);
            } else {
              commit('failed', result.message);
            }
            if(result.data.debugMsgs){
              window.console.log(result);
            }
            resolve(result);
          }).catch((err) => {
            if(err.data.debugMsgs){
              window.console.log(err);
            }
            commit('failed', err.message);
            reject(err);
          })
        })
      }

    };

    const transferFieldToModel = function(fields) {
      let nodes = [];
      fields.forEach((field, idx) => {
        if ("x" in field.loc && "y" in field.loc && "w" in field.loc && "h" in field.loc) {
          let model = { ...field.loc };
          model.xtype = field.xtype;
          model.cid = field.cid;
          model.text = field.text;
          model.fieldLabel = field.fieldLabel;
          model.allowBlank = field.allowBlank;
          model.fieldType = field.fieldType;
          model.renderType = field.rendererType;
          model.preventFreeDomain = field.preventFreeDomain;
          model.i = idx;
          model.name = field.name;
          model.decimalPrecision = field.decimalPrecision;
          model.minValue = field.minValue;
          model.maxValue = field.maxValue;
          model.value = field.value;
          model.readOnly = field.readOnly || false;
          model.maxSelections = field.maxSelections;
          model.maxLength = field.maxLength ? field.maxLength : 500;
          if ("options" in field) {
            model.options = field.options;
          }
          if ("boxLabel" in field) {
            model.fieldLabel = field.boxLabel;
          }
          nodes.push(model)
        }
      });
      return nodes;
    };

    const mutations = {
      setLayoutSetting(state, payload) {
        if ("layout" in payload) {
          state.fields = transferFieldToModel(payload.layout.fields);
          state.hiddenFields = payload.layout.hiddenFields;
        } else {
          state.fields = [];
          state.hiddenFields = [];
        }
        if ("setting" in payload) {
          state.setting = payload.setting;
        }
        if ("style" in payload) {
          state.styles = payload.style;
        }
        state.instanceId = payload.instanceId;
        state.objectId = payload.objectId;
      },
      setFormID(state,payload){
        state.formId = payload;
      },
      setDebugMode(state,payload){
        state.debugMode = payload;
      },
      success(state, payload) {
        state.status = "success"
      },
      failed(state, payload) {
        state.status = "failed"
      },
      api(state, payload) {
        state.api = payload;
      },
      init(state) {
        state.fields = [];
        state.hiddenFields = [];
      },
      rowHeight(state, value) {
        let gap = value.filter(({ property }) => property === "height");
        if (gap !== undefined) {
          state.rowHeight = parseInt(gap[0].value);
        } else {
          state.rowHeight = 80;
        }
      },
      verticalGap(state, value) {
        let gap = value.filter(({ property }) => property === "height");
        if (gap !== undefined) {
          state.verticalGap = parseInt(gap[0].value);
        } else {
          state.verticalGap = 0;
        }
      },
      horizontalGap(state, value) {
        let gap = value.filter(({ property }) => property === "width");
        if (gap !== undefined) {
          state.horizontalGap = parseInt(gap[0].value);
        } else {
          state.horizontalGap = 10;
        }
      },
    };


    return {
      namespaced: true,
      state,
      getters: { ...getters, getField },
      actions,
      mutations: { ...mutations, updateField }
    }
  }
}
