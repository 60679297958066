<template>
	<div>
		<div class="wintouch-label">
			{{item.text}}
		</div>
	</div>
</template>

<script>
    export default {
        name: "Label",
	    props:['item']
    }
</script>

<style scoped>

</style>
