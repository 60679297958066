<template>
	<div class="wintouch-field-checkbox">
		<input style="margin:5px;" :disabled="item.readOnly" v-model="value" type="checkbox" v-bind:id="item.cid" true-value="true" false-value="false" >
		<label style="border:none !important;" v-bind:for="item.cid">{{item.fieldLabel}}</label>
		<span class="wintouch-field-required" v-if="!item.allowBlank">*</span>
	</div>
</template>

<script>
    export default {
        name: "CheckBox",
		props:['item'],
		data(){
			return{
				value:this.item.value
			}
		},
	    mounted(){
            // window.console.log(this.item)
	    }
    }
</script>

<style scoped>

</style>
