import Vue from 'vue';
import qs from 'qs'
//
export default {
    validate(payload){
        payload.formData.action = "action.tenant.webform.v1.tokenbase.webformtokenbase.CheckFieldValueAction";
        return new Promise((resolve)=>{
            Vue.axios.post(payload.api+'action.do',
                qs.stringify(payload.formData)
            ).then(result => {
                resolve(result)
            }).catch(err=>{window.console.log(err);alert(err.message);});
        })
    },
    submit(payload){
        payload.formData.action = "action.tenant.webform.v1.tokenbase.webformtokenbase.SubmitWebformAction";
        return new Promise((resolve,reject)=>{
            Vue.axios.post(payload.api+'action.do',
                qs.stringify(payload.formData)
            ).then(result => {
                resolve(result.data)
            }).catch(err=>{
                    window.console.log(err);
                    // alert(err.message);
                    reject(err);
                }
            );
        })
    },
    get (payload) {
        payload.action = "action.tenant.webform.v1.tokenbase.webformtokenbase.RenderWebformAction";
        return new Promise((resolve) => {

            Vue.axios.post(payload.api+'action.do',
                qs.stringify({
                    action:payload.action,
                    webformToken:payload.webformToken
                })
            ).then(result => {
                    resolve(result.data)
                }).catch(err=>{
                    window.console.log(err);
                    alert(err.message);
                }
            );
        })
    }
}



