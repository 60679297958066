<template>
  <div class="wintouch-field-item">
    <div class="wintouch-field-label">
      <span v-if="item.xtype!=='wlabel' && item.xtype!=='component' && item.fieldType !== 2">{{item.fieldLabel}}</span><span class="wintouch-field-required" v-if="!item.allowBlank && item.fieldType !== 2 && item.xtype!=='wlabel' && item.xtype!=='component'">*</span>
    </div>
    <div class="wintouch-field-component" style="">
      <Input v-model="value" :item="item" v-if="[0,6,7,8].includes(item.fieldType)" @focusOut="focustOut" />
      <Number :item="item" v-if="item.fieldType===1" @focusOut="focustOut" />
      <Label :item="item" v-if="item.xtype==='wlabel'" />
      <Spinner :item="item" v-if="[0,1].includes(item.renderType)" @focusOut="focustOut" />
      <CheckBox :item="item" v-if="item.fieldType === 2" @focusOut="focustOut" />
      <RadioGroup :item="item" v-if="item.renderType === 2" @focusOut="focustOut" />
      <TextArea :item="item" :rowHeight="rowHeight" v-if="item.fieldType === 9" @focusOut="focustOut" />
      <Date :item="item" v-if="[3,4].includes(item.fieldType)" @focusOut="focustOut"/>
      <Time :item="item" v-if="item.fieldType === 17" @focusOut="focustOut"/>
      <Currency :item="item" v-if="item.fieldType === 10" @focusOut="focustOut"/>
    </div>
    <div ref="tip" class="wintouch-field-empty-tip" :style="{display:(this.showTip ? '':'none')}">
      {{error}}
    </div>
  </div>
</template>

<script>
  import Input from "./Input"
  import Number from "./Number"
  import Spinner from "./Spinner"
  import CheckBox from "./CheckBox"
  import RadioGroup from "./RadioGroup"
  import TextArea from "./TextArea"
  import Label from "./Label"
  import Date from "./Date"
  import Time from "./Time"
  import Currency from "./Currency"
  export default {
    name: "Field",
    components:{
      Input, Spinner, RadioGroup, CheckBox, Label, Number,TextArea,Date,Time,Currency
    },
    data(){
      return{
        showTip:false,
        value:"",
        name:"",
        error:"",
        invalidValue: false
      }
    },
    props: ['item','rowHeight'],
    mounted() {   
      if(this.item.fieldType === 5){
        console.log("?????????????????")
      }
    },
    methods:{
      focustOut(e){
        this.validate(e).then(()=>{}).catch(()=>{});
      },
      validate(e){
        this.$data.showTip = false
        let value = this.$children[0].$el.value;
        if([0,1].includes(this.item.renderType)){
          if(!this.item.allowBlank){
            if(this.$children[0].value instanceof Array){
              if(this.$children[0].value.length < 1){
                this.$data.showTip = true
                this.$data.error = "Please complete this required field.";
                reject();
              }
            }
          }
        }
        return new Promise((resolve, reject) => {

          if(this.item.fieldType === 7){
            if(value === ""){
              // console.log(this.item.allowBlank);
              if(!this.item.allowBlank){
                this.$data.showTip = true
                this.$data.error = "Please complete this required field.";
                reject();
              }else{
                resolve();
              }
            }else{
              this.$store.dispatch("webform/validateField", {
                key:this.item.name,
                value:value,
                preventFreeDomain:this.item.preventFreeDomain,
                webformToken:this.$store.state.webform.formId
              }).then((result) => {
                if(!result.success){
                  this.$data.showTip = true;
                  this.$data.error = result.message;
                  reject();
                }else{
                  this.$data.showTip = false;
                  resolve();
                }
              })
            }
          }

          if(!this.item.allowBlank && value === ""){
            this.$data.showTip = true
            this.$data.error = "Please complete this required field.";
            reject();
          }
          if(this.item.fieldType === 0) {
            if (this.item.maxLength && value.length > this.item.maxLength) {
              this.$data.showTip = true
              this.$data.error = "Exceed the maximum length limit.";
              reject();
            }
          }
          if (this.item.fieldType === 1) {
            if (this.item.decimalPrecision && (value.lastIndexOf('.') > 0 ? value.length - value.lastIndexOf('.') - 1 : 0) > this.item.decimalPrecision) {
              this.$data.showTip = true
              this.$data.error = "Exceed decimal precision limit.";
              reject();
            }
            if (this.item.minValue && +value < +this.item.minValue) {
              this.$data.showTip = true
              this.$data.error = "Less than minimum.";
              reject();
            }
            if (this.item.maxValue && +value > +this.item.maxValue) {
              this.$data.showTip = true
              this.$data.error = "Greater than maximum.";
              reject();
            }
          }
          // console.log(this.item.fieldLabel);
          resolve();
        });
      }
    }
    }
</script>

<style scoped>

</style>
