<template>
  <input v-model="value" :disabled="item.readOnly" class="wintouch-field-input" type="number" v-bind:placeholder="item.placeHolder" v-on:blur="handleBlur" :step="step" />
</template>
<script>
export default {
  name: "Number",
  props: ['item'],
  data() { return { value: this.item.value } },
  computed: {
    step() {
      return Math.pow(10, -1 * this.item.decimalPrecision).toFixed(this.item.decimalPrecision)
    }
  },
  methods: {
    handleBlur(e) {
      this.$emit("focusOut", e)
    }
  }

}
</script>
<style scoped>
input {
  width: 100%;
}
</style>