<template>
  <div>
<!--    <Multiselect-->
<!--        :disabled="item.readOnly"-->
<!--        @open="afterOpen"-->
<!--        :allow-empty="true"-->
<!--        class="wintouch-field-spinner"-->
<!--        v-model="value"-->
<!--        :multiple="item.renderType===1"-->
<!--        label="text"-->
<!--        :max="item.maxSelections"-->
<!--        track-by="text"-->
<!--        :taggable="true"-->
<!--        :close-on-select="false"-->
<!--        :options="getOptions()">-->
<!--      <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>-->
<!--    </Multiselect>-->
    <multiselect v-model="value"
                 :disabled="item.readOnly"
                 :max="item.maxSelections"
                 @open="afterOpen"
                 :options="getOptions()"
                 :multiple="item.renderType===1"
                 :allow-empty="true"
                 :close-on-select="item.renderType!==1"
                 :clear-on-select="false"
                 :preserve-search="true"
                 :placeholder="item.renderType !== 1 ? 'Select One Option' : `Select up to ${item.maxSelections}`"
                 label="text"
                 track-by="text">
      <template slot="selection" slot-scope="{ values, search, isOpen }">
        <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span>
      </template>
    </multiselect>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
  name: "Spinner",
  props: ['item'],
  data() {
    return {
      value: []
    }
  },
  mounted() {
    if (this.item.value instanceof Array) {
      this.item.value.forEach((value) => {
        let choosed = this.item.options.filter(option => option.value === value);
        if (choosed.length > 0) {
          this.value.push(choosed[0])
        }
      }, this)
    } else {
      let choosed = this.item.options.filter(option => option.value === this.item.value);
      if (choosed.length > 0) {
        this.value.push(choosed[0])
      }
    }
  },
  components: { Multiselect },
  methods:{
    getOptions: function() {
      return this.item.options.filter((v)=>{
        return v.hide !== true;
      })
    },
    afterOpen : function(){
      let show_timeout
      /* have to wait some till the list is rendered */
      show_timeout = setTimeout(() => {
        /* the multiselect field */
        let the_field = this.$el
        /* the element that should be scrolled */
        let the_wrapper = the_field.querySelector('.multiselect__content-wrapper')
        /* the selected option */
        let the_option = the_field.querySelector('span.multiselect__option.multiselect__option--selected')
        /* if everything is present */
        if ((the_wrapper !== null) && (the_option !== null)) {
          /* get the selected option's scroll top position */
          let option_top_offset = the_option.offsetTop
          /* set the scroll top position of the wrapper element */
          the_wrapper.scrollTop = option_top_offset
        }
        clearTimeout(show_timeout)
      }, 10)
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags input::placeholder{
  color: #adadad;
}
</style>