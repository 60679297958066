<template>
  <vue-timepicker  :disabled="item.readOnly" v-model="value" format="HH:mm:ss"></vue-timepicker>
</template>
<script>
import 'vue2-timepicker/dist/VueTimepicker.css'
import VueTimepicker from 'vue2-timepicker'
export default {
  name: "Time",
  props: ['item'],
  data() { return { value: this.item.value } },
  components: { VueTimepicker }
}
</script>
<style>
.time-picker {
  width: 100%;
}

.time-picker>input.display-time {
  width: 100% !important;
  height: 35px !important;
}
</style>