var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.fields.length > 0)?_c('div',[_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return (function (e){ return e.preventDefault(); })($event)}}},[_c('GridLayout',{ref:"grid",attrs:{"responsive":!!this.$isMobile(),"layout":_vm.fields,"col-num":12,"row-height":this.rowHeight,"is-draggable":false,"is-resizable":false,"margin":[this.horizontalGap,this.verticalGap],"use-css-transforms":false},on:{"update:layout":function($event){_vm.fields=$event}}},_vm._l((_vm.fields),function(item){return _c('grid-item',{key:item.cid,staticClass:"x-grid-item",attrs:{"x":item.x,"y":item.y,"w":item.w,"h":item.h,"i":item.i}},[_c('Field',{attrs:{"item":item,"rowHeight":_vm.rowHeight}})],1)}),1),_c('div',{ref:"hiddenContainer",staticClass:"hidden-fields"},_vm._l((_vm.hiddenFields),function(field){return _c('input',{key:'__F'+field.fieldId,attrs:{"type":"hidden","name":'__F'+field.fieldId},domProps:{"value":field.value}})}),0),(this.setting.allowAttachment)?_c('div',{staticClass:"file-pond-box"},[(this.setting.allowAttachment)?_c('file-pond',{ref:"pond",attrs:{"name":"file","allow-multiple":"true","max-files":10,"max-file-size":"5MB","server":{
          process: {
            url: this.$store.state.webform.api + 'action.do',
            method: 'POST',
            withCredentials: true,
            headers: {
              webformToken: this.$store.state.webform.formId
            },
            onload: function (response) {
              return this$1.$refs.pond.getFiles()[0].id
              // console.log(this.$refs.pond.getFiles()[0].filename)
              // console.log(this.$refs.pond.getFiles()[0].id)
            },
            timeout: 9000,
            ondata: function (formData) {
              formData.append('webformObjectId',this$1.$store.state.webform.objectId);
              formData.append('webformToken',this$1.$store.state.webform.formId);
              formData.append('instanceId',this$1.$store.state.webform.instanceId);
              formData.append('action','action.tenant.webform.v1.tokenbase.webformtokenbase.UploadWebformAttachmentAction')
              return formData;
            }
          },
          revert: null
        }},on:{"removefile":_vm.handleRemoveFile,"init":_vm.handleInit}}):_vm._e()],1):_vm._e(),(this.setting.other.showIp)?_c('div',{staticClass:"wintouch-gdpr-container"},[_c('div',{staticClass:"wintouch-gdpr-label wintouch-field-checkbox wintouch-gdpr-field"},[_vm._v("\n        "+_vm._s(this.setting.other.showIpText)+" "+_vm._s(this.setting.other.clientIp || 'Unknown')+"\n      ")])]):_vm._e(),(this.setting.other.enableGDPR)?_c('div',{staticClass:"wintouch-gdpr-container"},[_c('div',{staticClass:"wintouch-gdpr-field"},[_c('label',{staticClass:"wintouch-gdpr-label wintouch-field-checkbox",staticStyle:{"border":"none !important","height":"fit-content !important"},attrs:{"for":"gdpr"}},[_c('input',{ref:"gdpr",staticStyle:{"margin":"5px"},attrs:{"id":"gdpr","type":"checkbox","true-value":"true","false-value":"false"}}),_vm._v(_vm._s(_vm.setting.other.gdprText)+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),(this.showGDPRError)?_c('div',{ref:"tip",staticClass:"wintouch-field-empty-tip",staticStyle:{"margin-left":"23px"}},[_vm._v("\n          Please complete this required field.\n        ")]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"wintouch-submit-container"},[(this.setting.other.enableRecaptcha)?_c('vue-recaptcha',{ref:"recaptcha",attrs:{"sitekey":this.setting.other.recaptchaKey,"loadRecaptchaScript":true},on:{"verify":_vm.onVerify,"expired":_vm.onExpired}},[_c('button',{ref:"submitBtn",staticClass:"wintouch-submit-button",class:{ disabled: _vm.isSubmitting}},[_vm._v(_vm._s(_vm.setting.other.btnText))])]):_vm._e(),(!this.setting.other.enableRecaptcha)?_c('button',{ref:"submitBtn",staticClass:"wintouch-submit-button",class:{ disabled: _vm.isSubmitting},on:{"click":_vm.beforeOnSubmit}},[_vm._v(_vm._s(_vm.setting.other.btnText))]):_vm._e()],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }