<template>
	<input v-model="value" :disabled="item.readOnly" class="wintouch-field-input" type="text" v-bind:placeholder="item.placeHolder" v-on:blur="handleBlur"/>
</template>

<script>
    export default {
        name: "Input",
        props:['item'],
		data(){
			return {
				value:this.item.value
			}
		},
        methods: {
            handleBlur(e){
				this.$emit("focusOut",e)
            }
        }

    }
</script>

<style scoped>
	input{
		width:100%;
	}
</style>
