<template>
  <div ref="container" class="wintouch-form">
    <div class="wintouch-form-blank" v-if="fields.length === 0">

      <span v-if="this.isLoading">
        <loading></loading>
      </span>
      <span style="padding:12px;" v-if="!this.isLoading">
        Use the Layout tab to add fields and to configure the layout.
      </span>
    </div>
    <Grid :formId="formId" @setDetailMsg="setDetailMsg" />
    <modal :name="'modal-'+formId" :clickToClose="false" :classes = "'renderer-modal-window'" :adaptive="true" :reset="true" :width="'65%'" :height="'auto'">
      <div class="wintouch-notice" v-if="status === 'success'">
        <div class="wintouch-notice-msg wintouch-success-message">{{setting.msg.success}}</div>
        <div class="wintouch-submit-container">
          <button v-on:click="doOk" ref="submitBtn" class="wintouch-submit-button">{{setting.other.successBtnText}}</button>

        </div>
      </div>
      <div class="wintouch-notice" v-if="status === 'failed'">
        <div class="wintouch-notice-msg">{{setting.msg.fail}}</div>
        <div v-if="!hasShowDetailMsg" @click="showDetailMsg" class="detailMsgBtn">Show Detail Message</div>
        <div v-if="hasShowDetailMsg" class="detailMsgContent">{{detailMsg}}</div>
        <div class="wintouch-submit-container">
          <button v-on:click="closeModal" ref="submitBtn" class="wintouch-submit-button">{{setting.other.failBtnText}}</button>
        </div>
      </div>
      <div class="wintouch-notice" v-if="status === ''">
        <div class="wintouch-notice-msg">You need Read and Check GDPR Agreement before submit the form.</div>
        <div class="wintouch-submit-container">
          <button v-on:click="closeModal" ref="submitBtn" class="wintouch-submit-button">OK</button>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import Vue from 'vue';
import { createHelpers } from "vuex-map-fields";

import css from 'css';
import Grid from "./components/layouts/Grid";
import loading from './assets/loading.svg';
import VueGtag from "vue-gtag";
Vue.use(css);

const { mapFields } = createHelpers({
  getterType: 'webform/getField',
  mutationType: 'webform/updateField',
});

export default {
  name: 'App',
  data() {
    return {
      detailMsg: '',
      hasShowDetailMsg: false,
      isLoading: true,
      debugMode: false
    }
  },
  created() {
    Vue.prototype.$extra = this.extra;
    this.$store.commit("webform/init");
    let me = this;
    this.$store.commit("webform/api", this.api);
    window.reloadWintouchForm = function(config) {
      me.debugMode = config.debugMode;
      me.$store.commit("webform/init");
      me.loadSetting(config.webformtoken.split('wt-form-')[1], config.api);
    }
  },
  computed: {
    // types: ({$store}) => $store.state.dataTypes,
    ...mapFields(["fields", "hiddenFields", "styles", "rowHeight", "setting", "status"])
  },
  components: {
    Grid,loading
  },
  props: {
    formId: String,
    api: String,
    extra: Object,
    isPopup: Boolean
  },
  mounted() {
    this.loadSetting(this.formId, this.api);
  },
  methods: {
    showDetailMsg() {
      this.hasShowDetailMsg = true
    },
    setDetailMsg(msg) {
      if(msg.message){
        this.detailMsg = msg.message
      }else{
        this.detailMsg = msg
      }
    },
    closeModal() {
      this.hasShowDetailMsg = false;
      this.$modal.hide("modal-"+this.formId);
      if (this.setting.other.failRedirectUrl && this.setting.other.failRedirectUrl !== '') {
        location.href = this.setting.other.failRedirectUrl
      }
    },

    doOk() {
      this.hasShowDetailMsg = false;
      this.$modal.hide("modal-"+this.formId);
      if (this.setting.other.successRedirectUrl && this.setting.other.successRedirectUrl !== '') {
        location.href = this.setting.other.successRedirectUrl
      }else{
        window.location.reload();
      }
    },

    loadSetting(formId, api) {
      let me = this;
      this.$store.dispatch("webform/get", { formId: formId, api: api, debugMode: this.debugMode }).then(() => {
        let old = document.getElementById("wintouch-web-form-style");
        if (old != null) {
          old.parentNode.removeChild(old);
        }
        let style = document.createElement('style');
        style.id = "wintouch-web-form-style";
        style.type = "text/css";
        style.appendChild(document.createTextNode(me.buildCssFromDefs(me.styles)));
        me.styleNode = style.childNodes[0]; // a reference I store in the data hash
        document.head.appendChild(style);
        this.isLoading = false;
        if(this.$store.state.webform.setting.other.enableGtag){
          window.console.log(this.$store.state.webform.setting.other.gtagResourceId);
          Vue.use(VueGtag, {
            config: { id: this.$store.state.webform.setting.other.gtagResourceId }
          });
        }
      })
    },
    buildCssFromDefs(defs) {
      let me = this;
      let result = this.styles.replace(/@import\s+url\([^)]+\)/g, function(match) {
        return match.replace(/;/g, '%3B');
      });
      let cssObj = css.parse(result);
      let styleBody = "";
      cssObj.stylesheet.rules.forEach((rule) => {
        let cssTag = me.toCssTag(rule);
        styleBody += cssTag;
      });
      return styleBody;
    },
    toCssTag(rule) {
      let css = "";
      if (rule.type === "rule") {
        switch (rule.selectors[0]) {
          case "success-message":
            css += this.buildSuccessMessage(rule);
            break;
          case "field-input":
            css += this.buildFieldStyle(rule);
            break;
          case "field-label":
            css += this.buildFieldLabelStyle(rule);
            break;
          case "field-radio-checkbox":
            css += this.buildRadioFieldStyle(rule);
            break;
          case "submit-button":
            css += this.buildButtonStyle(rule);
            break;
          case "label":
            css += this.buildLabelStyle(rule);
            break;
          case "gdpr-container":
            css += this.buildGdprContainerStyle(rule);
            break;
          case "gdpr-field":
            css += this.buildGdprFieldStyle(rule);
            break;
          case "form-container":
            css += this.buildContainerStyle(rule);
            break;
          case "row":
            this.$store.commit("webform/rowHeight", rule.declarations);
            break;
          case "cell":
            css += this.buildCellStyle(rule);
            break;
          case "field-gap-vertical":
            this.$store.commit("webform/verticalGap", rule.declarations);
            break;
          case "field-gap-horizontal":
            this.$store.commit("webform/horizontalGap", rule.declarations);
            break;
          default:
            break;
        }
      }else if(rule.type === 'import'){
        css += ("@import " + rule.import + ';');
      }
      return css;
    },
    buildCellStyle(rule) {
      let head = ".wintouch-field-item{";
      return head + this.parseDeclare(rule) + "}";
    },
    buildContainerStyle(rule){
      let head = ".wintouch-form{";
      return head + this.parseDeclare(rule) + "}";
    },
    buildSuccessMessage(rule){
      let head = ".wintouch-success-message{";
      return head + this.parseDeclare(rule) + "}";
    },
    buildFieldStyle(rule) {
      let head = ".wintouch-field-input,.vdpComponent.vdpWithInput>input,.time-picker>input, .wintouch-field-component .multiselect__tags{";
      return head + this.parseDeclare(rule) + "}";
    },
    buildRadioFieldStyle(rule){
      let head = ".wintouch-field-checkbox, .wintouch-field-radio{";
      return head + this.parseDeclare(rule) + "}";
    },
    buildLabelStyle(rule) {
      let head = ".wintouch-label{";
      return head + this.parseDeclare(rule) + "}";
    },
    buildGdprFieldStyle(rule) {
      let head = ".wintouch-gdpr-field{";
      return head + this.parseDeclare(rule) + "}";
    },
    buildGdprContainerStyle(rule) {
      let head = ".wintouch-gdpr-container{";
      return head + this.parseDeclare(rule) + "}";
    },
    buildButtonStyle(rule) {
      let head = ".wintouch-submit-button{";
      return head + this.parseDeclare(rule) + "}";
    },
    buildFieldLabelStyle(rule) {
      let head = ".wintouch-form .wintouch-field-label{";
      return head + this.parseDeclare(rule) + "}";
    },
    parseDeclare(rule) {
      let body = "";
      rule.declarations.forEach((declare) => {
        if (declare.type === "declaration") {
          let p = declare.property;
          let v = declare.value + " !important";
          body += p + ":" + v + ";"
        }

      });
      return body;
    }
  }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

.wintouch-submit-container {
  display: flex;
  justify-content: center;
}


.wintouch-form .wintouch-field-component .wintouch-field-input,
.vdpComponent.vdpWithInput>input,
.time-picker>input {
  display: inline-block;
  width: 100%;
  height: 35px;
  padding: 9px 10px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
  color: #33475b;
  border: 1px solid #cbd6e2;
  box-sizing: border-box;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.wintouch-form .wintouch-field-component textarea.wintouch-field-input {
  height: 100% !important;
  resize: none;
}

.wintouch-form .wintouch-field-label {
  font-size: 14px;
  width: 130px;
  color: #33475b;
  display: flex;
  float: none;
  width: auto;
  font-weight: 500;
  line-height: 20px;
  padding-top: 0;
  margin-bottom: 4px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  min-height: 20px;
}
.wintouch-form .wintouch-field-label span{
  display:flex;
}

.wintouch-form .wintouch-field-required {
  color: red;
}

.wintouch-label {
  text-align: center;
}

.wintouch-form .wintouch-field-empty-tip {
  color: red;
  font-size: 12px;
}

.wintouch-form .wintouch-submit-button {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 10px;
  float: right;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  line-height: 12px;
  position: relative;
  text-align: center;
  background-color: #ff7a59;
  border-color: #ff7a59;
  color: #fff;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
  padding: 12px 24px;
}

.wintouch-field-spinner .multiselect__tags {
  min-height: 35px;
  display: block;
  padding: 2px 40px 0 8px;
}

.wintouch-notice {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content !important;
  padding: 48px 0;
  justify-content: center;
  align-items: center;
}

.wintouch-notice .wintouch-notice-msg {
  margin: 50px 50px 20px 50px;
  text-align: center;
  font-size: 20px;
}

.wintouch-notice .detailMsgContent {
  padding: 20px;
  font-size: 14px;
  width: calc(100% - 50px);
  box-sizing: border-box;
  background: #e6e6e6;
}

.wintouch-notice .detailMsgBtn {
  color: #969696;
  font-size: 14px;
}

.wintouch-form-blank {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.wintouch-form-blank {
  color: #b2b2b2;
  font-size: 30px;
}
.multiselect__input:focus{
  border:none;
}
.wintouch-form .wintouch-field-component .multiselect__tags{
  padding-top: unset;
}
.wintouch-field-component .multiselect__tags{
  min-height: unset;
  display: flex;
  align-items: center;
}
.wintouch-field-component .multiselect__single{
  margin-bottom: 0px;
}
</style>
