<template>
	<textarea :style="textAreaStyle"  :disabled="item.readOnly"  v-on:blur="handleBlur" class="wintouch-field-input" v-model="value" placeholder="Click to enter text."></textarea>
</template>

<script>
    import {createHelpers} from "vuex-map-fields";
    const { mapFields } = createHelpers({
        getterType: 'webform/getField',
        mutationType: 'webform/updateField',
    });
    export default {
        name: "TextArea",
	    props:["item"],
        computed: {
            // types: ({$store}) => $store.state.dataTypes,
            ...mapFields(["rowHeight","verticalGap"]),
            textAreaStyle(){
                let h = this.rowHeight  * this.item.h - 24 - 20 + (this.item.h - 1) * this.verticalGap + 'px !important';
                return {
                    "height": h
                };
            }
        },
		data(){
			return {
				value:this.item.value
			}
		},
        methods: {
            handleBlur(e) {
                this.$emit("focusOut", e)
            }
        }
    }
</script>

<style scoped>

</style>
