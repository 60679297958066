<template>
	<currency-input class="wintouch-field-input" :disabled="item.readOnly" v-model="value" locale="en-US" v-on:blur="handleBlur" currency="USD"/>
</template>

<script>
	import Vue from 'vue';
    import VueCurrencyInput from 'vue-currency-input'
    Vue.use(VueCurrencyInput);
    export default {
        name: "Currency",
		// components:{currency:VueCurrencyInput},
	    props:["item"],
		data(){
			return {value:Number(this.item.value)}
		},
        methods: {
            handleBlur(e){
                this.$emit("focusOut",e)
            }
        }
    }
</script>

<style scoped>

</style>
