<template>
  <div class="wintouch-field-radio">
    <!--    <div v-for="option in item.options" v-bind:key="option.value">-->
    <!--      <input :checked="true" v-model="value" type="radio" v-bind:name="item.cid" v-bind:id="[option.value+item.cid]" v-bind:value="item.value">-->
    <!--      <label style="border:none !important;" v-bind:for="[option.value+item.cid]">{{option.text}}</label>-->
    <!--    </div>-->
    <div v-for="option in item.options" v-bind:key="option.value" class="wintouch-field-radio-item">
      <input style="margin:5px;" :disabled="item.readOnly" type="radio" :checked="option.value===item.value" :name="item.name" :value="option.value" :id="option.value+item.cid" @change="onChanged" />
      <label style="border:none !important;" :for="option.value+item.cid">{{option.text}}</label>
    </div>
    <!--    -->
    <!--    <input type="radio" :checked="true" name="test2" value="2"/>-->
    <!--    <input type="radio" :checked="true" name="test3" value="3"/>-->
  </div>
</template>
<script>
export default {
  name: "RadioGroup",
  props: ['item'],
  data() {
    return {
      value: this.item.value
    }
  },
  methods: {
    onChanged(e) {
      this.value = e.target.value;
    }
  }
}
</script>
<style scoped>
.wintouch-field-radio {
  display: flex;
}

.wintouch-field-radio-item {
  margin: 5px;
}
</style>