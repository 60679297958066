<template>
  <date-picker v-model="value"  :disabled="item.readOnly" :type="item.fieldType === 4 ? 'datetime' : 'date'" :value-format="item.fieldType === 4 ? 'yyyy-MM-dd HH:mm:ss': 'yyyy-MM-dd'"></date-picker>
</template>
<script>
import { DatePicker } from 'element-ui'
export default {
  name: "Date",
  props: ['item'],
  components: { DatePicker },
  data() {
    return {
      value: this.item.value || ''
    }
  }
}
</script>
<style>
.el-date-picker {
  z-index: 99999 !important;
}

.el-date-editor {
  width: 100% !important;
}

.el-date-editor .el-input__inner {
  height: 35px !important;
  background-color: #ffffff !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 16px !important;
  font-weight: normal !important;
  line-height: 22px !important;
  color: black !important;
  border: 1px solid #cbd6e2 !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #cbd6e2 !important;
  border-radius: 3px !important;
}

.el-picker-panel__link-btn {
  border: none;
  margin: 0 5px;
  padding: 4px 10px;
  border-radius: 4px;
}
</style>