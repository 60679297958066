import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import axios from 'axios';
import VueAxios from 'vue-axios'
import 'es6-promise/auto'
import VModal from 'vue-js-modal'
import { DatePicker } from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import VueMobileDetection from 'vue-mobile-detection'
import {WebFormModule} from './stores/webform';
// import VueGtag from "vue-gtag";
// Vue.use(VueGtag, {
//   config: { id: "UA-226409473-2" }
// });
Vue.use(VueMobileDetection);


locale.use(lang);
Vue.use(DatePicker);
Vue.use(VModal);

//
Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
Vue.use(Vuex);

axios.interceptors.request.use(request => {

  // loader = Vue.$loading.show({
  //   zIndex: 1090
  // });
  request.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
  return request;
});

axios.interceptors.response.use(function(response) {
  if(response.data.success === false){
    return Promise.reject(response.data);
  }
  return response;
}, function(error) {
  // loader.hide();
  window.console.log(error);
  if (error.response === undefined) {
    alert(error);
    return Promise.reject(error);
  }
  if (error.response) {
    alert(error.response.data.message);
    return Promise.reject(error);
  }
  if (error.response.status === 401) {
    alert(401);
    return Promise.reject(error);
  }
  alert(error);
  return Promise.reject(error);
});

let roots = document.getElementsByName("wt-form");
let ids = [];
let api = "";
roots.forEach(function(item) {
  ids.push(item.id);
  api = item.getAttribute("api");
});
ids.forEach(function(id) {
  const instance = new Vuex.Store({
    state:{
    },
    modules: {
      "webform": WebFormModule.instance
    }
  });
  new Vue({
    store:instance,
    render: h => h(App, {
      props: {
        formId: id.split('wt-form-')[1],
        api: api
      }
    }),
  }).$mount("#" + id)
});

window.mountWintouchForm = function(config) {
  const instance = new Vuex.Store({
    state:{
    },
    modules: {
      "webform": WebFormModule.instance
    }
  });
  new Vue({
    store: instance,
    render: h => h(App, {
      props: {
        formId: config.webformtoken.split('wt-form-')[1],
        api: config.api,
        isDialog: config.isDialog,
        extra:config.extra
      }
    }),
  }).$mount("#" + config.webformtoken)
};
